
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { lostelkUrl } from '../../global';
import store, { CardList, GloabSearchProps } from '../../store';
import Likes from '../cardFun/Likes.vue';
import SearchFailure from '../globalFun/SearchFailure.vue';
import DownloadFile from '../cardFun/DownloadFile.vue';
import GoCommentButton from '../cardFun/GoCommentButton.vue';
import router from '../../router';
import { socket } from '../../service/service';

export default defineComponent({
  name: 'CardMain',
  components: {
    Likes,
    SearchFailure,
    DownloadFile,
    GoCommentButton,
  },
  props: {
    list: {
      type: Array as PropType<CardList[]>,
      required: true,
    },
    cardColumnSize: {
      type: Number,
      required: true,
    },
    detailsUrlparameter: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const isShowLoadingMore = computed(() => store.state.isShowLoadingMore);

    const noMore = computed(() => store.state.noMore);

    const cardList = computed(() => props.list);

    const ColumnSize = computed(() => props.cardColumnSize);
    /**
     * 三列卡片分配
     */
    const cardThreeColumnOne = computed(() => {
      return cardList.value.filter((item, index) => {
        if ((index + 1) % 3 === 1) {
          return item;
        }
      });
    });

    const cardThreeColumnTwo = computed(() => {
      return cardList.value.filter((item, index) => {
        if ((index + 1) % 3 === 2) {
          return item;
        }
      });
    });

    const cardThreeColumnThree = computed(() => {
      return cardList.value.filter((item, index) => {
        if ((index + 1) % 3 === 0) {
          return item;
        }
      });
    });

    /**
     * 两列卡片分配
     */
    const cardTwoColumnOne = computed(() => {
      return cardList.value.filter((item, index) => {
        if ((index + 1) % 2 === 1) {
          return item;
        }
      });
    });

    const cardTwoColumnTwo = computed(() => {
      return cardList.value.filter((item, index) => {
        if ((index + 1) % 2 === 0) {
          return item;
        }
      });
    });

    /**
     * 获得搜索状态
     */
    const searchfailure = computed(() => store.state.searchFailure);

    /**
     * 获得搜索的标签
     */
    const sarchTag = computed(() => (store.state.searchVal as GloabSearchProps) || {});

    /**
     * 修改
     * 获得
     * 搜索栏显示状态
     */
    const mainSearchIsNone = computed(() => store.state.mainSearchIsNone);

    /**
     * 点击跳转详情页
     */
    const Urlparameter = computed(() => props.detailsUrlparameter);
    const cardDetails = async (postId: number) => {
      // 将body设置为不可滚动
      document.body.style.overflow = 'hidden';

      await store.commit('showCommentsCut', false);
      await router.push(`/${Urlparameter.value}/${postId}`);
    };

    /**
     * 进入用户页
     */
    const toUserPage = (userName: string, userId: number) => {
      router.push(`/@${userId}`);
    };

    /**
     * 实时获取cardColumn宽度
     */
    const cardColumnWidth = ref();
    const cardColumn = ref();

    function personalcardColumnSize() {
      cardColumnWidth.value = cardColumn.value.clientWidth;
    }

    /**
     * 监听实时服务端点赞事件
     */
    const onUserLikePostCreated = (data: { postId: number; socketId: string }) => {
      const { postId, socketId } = data;
      if (socket.id === socketId) return;
      store.commit('realTimeClickLike', postId);
    };

    socket.on('userLikePostCreated', onUserLikePostCreated);

    /**
     * 监听实时服务端取消点赞事件
     */
    const onUserLikePostDelete = (data: { postId: number; socketId: string }) => {
      const { postId, socketId } = data;
      if (socket.id === socketId) return;
      store.commit('realTimeCancelLike', postId);
    };

    socket.on('userLikePostDelete', onUserLikePostDelete);

    onMounted(() => {
      cardColumnWidth.value = cardColumn.value.clientWidth;
      window.addEventListener('resize', personalcardColumnSize, false);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', personalcardColumnSize, false);

      socket.off('userLikePostCreated', onUserLikePostCreated);
      socket.off('userLikePostDelete', onUserLikePostDelete);
    });

    return {
      lostelkUrl,
      cardList,
      Likes,
      cardThreeColumnOne,
      cardThreeColumnTwo,
      cardThreeColumnThree,
      cardTwoColumnOne,
      cardTwoColumnTwo,
      searchfailure,
      sarchTag,
      cardDetails,
      ColumnSize,
      mainSearchIsNone,
      Urlparameter,
      isShowLoadingMore,
      noMore,
      toUserPage,
      cardColumn,
      cardColumnWidth,
    };
  },
});
